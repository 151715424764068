.partners {
  margin-bottom: 175px;

  @include vp-1023 {
    margin-bottom: 60px;
  }

  @include vp-767 {
    margin-bottom: 74px;
  }

  &__title {
    margin-top: 0;
    margin-bottom: 60px;
    margin-left: 230px;

    @include vp-1023 {
      margin-bottom: 42px;
      margin-left: 0;
    }

    @include vp-767 {
      margin-bottom: 36px;
    }
  }

  &__list {
    display: flex;
    align-items: center;
    margin: 0;
    padding-left: 0;
    list-style: none;

    &.swiper-wrapper {
      transition-timing-function: linear;
    }
  }

  &__item.swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    width: max-content;
    margin-right: 75px;

    @include vp-767 {
      height: 65px;
      margin-right: 33px;
    }

    img {
      width: auto;
      height: 100%;
      object-fit: cover;
    }
  }
}
