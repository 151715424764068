.top-requests {
  margin-bottom: 111px;
  padding-top: 37px;


  @include vp-1023 {
    padding-top: 40px;
    margin-bottom: 67px;
  }

  @include vp-767 {
    margin-bottom: 64px;
  }

  &__title {
    margin: 0;
    text-align: center;

    @include vp-1023 {
      text-align: left;
    }

    span {
      @include vp-767 {
        display: none;
      }
    }
  }

  &__swiper {
    padding-bottom: 60px;

    @include vp-1850 {
      max-width: 1850px;
      overflow: visible;
    }

    @include vp-1023 {
      padding: 0 20px 16px;
      margin: 0;
    }

    @include vp-767 {
      padding-bottom: 18px;
    }
  }

  &__list {
    margin-top: 0;
    margin-bottom: 28px;
    padding-top: 35px;
    padding-left: 0;
    min-height: 238px;
    list-style: none;

    @include vp-1023 {
      padding-top: 23px;
    }

    @include vp-767 {
      padding-top: 25px;
    }
  }

  &__item {
    width: 599px;
    min-height: 230px;
    max-height: 230px;
    box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    transition: min-height $trans-default;
    margin-right: 51px;

    @include vp-1023 {
      min-height: 440px;
      max-width: 338px;
      height: auto;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      padding: 0;
      margin-right: 18px;
    }

    @include vp-767 {
      margin-right: 10px;
    }

    & > a {
      height: 100%;
      min-height: 240px;
      padding: 24px 23px 15px 29px;
      display: block;

      @include vp-1023 {
        min-height: 440px;
        padding: 27px 28px 28px 18px;
      }

      @include vp-767 {
        padding-bottom: 0;
      }

      @media (max-width: $vp-320) {
        padding-left: 6px;
        padding-right: 6px;
      }
    }

    &.swiper-slide-active {
      @include vp-1024 {
        min-height: 240px;
      }
    }
  }

  &__heading {
    margin: 0;
    margin-bottom: 17px;
    padding-bottom: 14px;
    font-size: 30px;
    line-height: 35px;
    text-align: center;

    &--blue {
      border-bottom: 4px solid $color-havelock-blue;
    }

    &--flamingo {
      border-bottom: 4px solid $color-flamingo;
    }

    &--tree {
      border-bottom: 4px solid $color-silver-tree;
    }

    @include vp-1023 {
      width: 85%;
      padding-bottom: 0;
      margin-bottom: 18px;
      border: none;
      line-height: 28px;
      text-align: start;
    }
  }

  &__text {
    width: 98%;
    margin: 0;
    font-size: 18px;
    line-height: 137.7%;

    @include vp-1023 {
      width: 94%;
      margin-bottom: -11px;
      line-height: 190.19%;
      color: $color-dark-gray;
    }

    @include vp-767 {
      width: 90%;
      margin-bottom: -18px;
      margin-bottom: 10px;
    }
  }

  &__pagination {
    .swiper-pagination-bullet {
      width: 15px;
      height: 15px;
      background-color: $color-white-smoke;
      opacity: 1;
    }

    .swiper-pagination-bullet-active {
      background-color: $color-silver;
    }
  }

  .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: 0;
  }
}
