.liders-advantages__inner {
  margin: 0 auto;
}

.liders-advantages__inner h2 {
  max-width: 600px;
  margin: 0 0 126px;

  @include vp-767 {
    margin: 0 0 44px;
  }
}

.liders-advantages__inner svg {
  fill: transparent;
  margin-bottom: 24px;
}

.liders-advantages__list {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  row-gap: 120px;

  @include vp-550 {
    flex-direction: column;
    margin: 0 auto;
    row-gap: 40px;
  }
}

.liders-advantages__item {
  min-width: 50%;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @include vp-1023 {
    flex-wrap: wrap;
  }

  @include vp-550 {
    min-width: 100%;
    margin-bottom: 50px;

    &:last-child {
      margin: 0;
    }
  }
}

.liders-advantages__item p {
  color: $color-grey-accordion;
  font-size: 18px;
  line-height: 32px;
  text-align: center;
  max-width: 345px;
  margin: 0;
  padding: 0;

  @include vp-1023 {
    font-size: 16px;
    line-height: 27px;
    padding: 0 10px;
  }

  @include vp-550 {
    max-width: 255px;
  }
}

.liders-organization .schedule {
  @include vp-1023 {
    & span {
      display: block;
    }
  }
}
