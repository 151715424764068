.main-video {
  position: relative;
  min-height: 1044px;
  padding-top: 317px;
  padding-bottom: 50px;

  @include vp-1023 {
    padding-top: 182px;
    min-height: 677px;
  }

  &__heading.title {
    margin-top: 0;
    text-align: center;
    margin-bottom: 220px;

    @include vp-1023 {
      width: 80%;
      margin: 0 auto;
      line-height: 78px;
      margin-bottom: 200px;
    }

    @include vp-767 {
      line-height: 51px;
    }
  }

  &__heading.title.is-transparent {
    opacity: 1;
  }

  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -10;
  }

  &__cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    transition: opacity $trans-default;
    opacity: 1;
    visibility: visible;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &.is-hidden {
      opacity: 0;
      visibility: hidden;
    }
  }

  &__play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translate(-50%, -50%);
    padding: 100px;
    border-radius: 50%;
    background-color: transparent;
    z-index: 1;
    transition: background-color $trans-default;

    @include vp-1023 {
      padding: 80px;
    }

    @include vp-767 {
      padding: 50px;
    }

    @include no-touch {

      &:hover,
      &:focus {
        background-color: $color-dodger-blue;
      }
    }

    &::after {
      content: "";
      background: url("../img/svg/play-button.svg") no-repeat;
      width: 150px;
      height: 150px;
      background-size: 150px;
      transform: rotate(90deg);

      @include vp-1023 {
        width: 100px;
        height: 100px;
        background-size: 100px;
      }

      @include vp-767 {
        width: 70px;
        height: 70px;
        background-size: 70px;
      }
    }
  }

  &__play-button.is-button-visible {
    display: none;
  }
}

.main-video .btn--blue {
  background-color: $color-plug-button;
  border: 0;
  font-size: 80px;
  line-height: 104px;
  border-radius: 0;
  box-shadow: 8px 10px 0 $color-shadow-plug-button;
  padding: 11px 30px;

  @include vp-1023 {
    font-size: 30px;
    line-height: 39px;
    padding: 22px 30px;
    box-shadow: 4px 7px 0 #acc6de;
  }
}
