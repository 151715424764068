.title {
  padding: 0;
  font-weight: 700;
  color: inherit;

  &--h1 {
    font-size: 100px;
    line-height: 1.3;

    @include vp-1023 {
      font-size: 60px;
      line-height: 1.2;
    }

    @include vp-767 {
      font-size: 40px;
      line-height: 1.3;
    }
  }

  &--h2 {
    font-size: 42px;
    line-height: 1.2;

    @include vp-1023 {
      font-size: 24px;
    }
  }

  &--h3 {
    font-size: 30px;
    line-height: 1.3;

    @include vp-1023 {
      font-size: 24px;
    }
  }

  &--h4 {
    font-size: 22px;
    line-height: 1.2;

    @include vp-1023 {
      font-size: 18px;
    }
  }

  &--h5 {
    font-size: 18px;
    line-height: 1.8;
  }

  &--black {
    color: $color-default-black;
  }

  &--white {
    color: $color-default-white;
  }


}
