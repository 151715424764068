.team-member-form{
  &__container{
    box-sizing: border-box;
    max-width: 265px;
    padding: 25px;
    margin: 0 auto;

    box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 24px;
  }


  &--desktop{

    @include vp-767{
      display: none;
    }

  }

  &--mobile{

    display: none;
    @include vp-767{
      display: block;
    }
  }

}
