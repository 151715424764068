.reviews {
  margin-bottom: 157px;

  @include vp-1023 {
    margin-bottom: 60px;
  }

  &__title {
    margin-left: 230px;
    margin-top: 0;
    margin-bottom: 82px;
    font-weight: 700;
    font-size: 42px;
    line-height: 49px;

    @include vp-1023 {
      margin-left: 0;
      margin-bottom: 32px;
      font-size: 24px;
      line-height: 28px;
    }

    @include vp-767 {
      margin-bottom: 21px;
    }
  }

  &__container {
    position: relative;
    overflow: hidden;
    margin: 0 auto;
    max-width: 796px;

    @include vp-1023 {
      width: 81%;
    }

    @include vp-767 {
      width: 90%;
    }
  }

  &__list {
    margin-top: 0;
    margin-bottom: 40px;
    padding-left: 0;
    list-style: none;
    width: 100%;
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    color: $color-dark-slategray;
  }

  &__block {
    margin-top: 0;
    margin-bottom: 30px;
    width: 65%;
    min-height: 275px;

    @include vp-1023 {
      margin-bottom: 7px;
      width: 55%;
    }

    @include vp-767 {
      width: 100%;
    }
  }

  &__text {
    position: relative;
    margin: 0;
    padding: 56px 32px 108px;
    font-weight: 700;
    font-size: 18px;
    line-height: 32px;

    @include vp-1023 {
      padding-bottom: 21px;
    }

    @include vp-767 {
      padding-top: 56px;
      padding-right: 20px;
    }

    &::after,
    &::before {
      position: absolute;
      font-weight: 700;
      font-size: 108px;
      line-height: 108px;
      color: $color-dodger-blue;
    }

    &::before {
      content: "“";
      top: 0;
      left: -17px;

      @include vp-767 {
        left: -5px;
      }
    }

    &::after {
      content: "”";
      line-height: 127px;
      bottom: -30px;
      right: 10px;

      @include vp-1023 {
        display: none;
      }
    }
  }

  &__autour {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: auto;
    font-size: 22px;
    line-height: 39px;

    @include vp-1023 {
      width: 35%;
      font-size: 16px;
      line-height: 28px;
      text-align: center;
    }

    @include vp-767 {
      width: 60%;
    }
  }

  &__post {
    margin-top: 0;
    margin-bottom: 25px;
  }

  &__controls {
    position: absolute;
    top: 109px;
    display: flex;
    justify-content: space-between;
    width: 100%;

    @include vp-767 {
      display: none;
    }
  }

  &__control {
    display: flex;
    padding: 0;
    width: 44px;
    height: 84px;
    border: none;
    background-color: $color-transparent;
    cursor: pointer;
    color: $color-light-telegray;
    transition: color $trans-default;
    z-index: 2;

    &--front {
      transform: rotate(180deg);
    }


    @include no-touch {
      &:hover {
        color: $color-dodger-blue;
      }
    }

    &[disabled] {
      opacity: 0.5;
      cursor: default;

      @include no-touch {
        &:hover {
          color: $color-light-telegray;
        }
      }
    }
  }

  &__video {
    height: 275px;

    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__logo {
    height: 78px;

    @include vp-1023 {
      height: 44px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .swiper-pagination-bullet {
    width: 15px;
    height: 15px;
    background-color: $color-dark-gray;
  }

  .swiper-pagination-bullet-active {
    background-color: $color-silver;
  }
}
