.main-block.main-involver {
  padding-top: 320px;

  @include vp-1023 {
    padding-top: 216px;
  }

  @include vp-767 {
    margin-bottom: 149px;
  }

  & h1 {
    margin-bottom: 20px;
  }

  & .main-block__icon {
    text-align: center;
  }

  & .btn {
    font-family: "Roboto", "Arial", sans-serif;
    font-size: 18px;
    line-height: 21px;
  }

  & .picture-wrapper {
    @include vp-768-and-vp-1023 {
      height: 112%;
    }

    @include vp-767 {
      top: 0;
      height: 127%;
    }
  }
}

.main-involver__under-text p {
  font-family: "Roboto", "Arial", sans-serif;
  font-size: 50px;
  line-height: 65px;
  font-weight: 700;
  text-align: center;

  @include vp-767 {
    font-size: 22px;
    line-height: 29px;
  }
}

.btn span[data-involver="text-desktop"] {
  @include vp-1023 {
    display: none;
  }
}

.btn span[data-involver="text-tablet"] {
  display: none;

  @include vp-768-and-vp-1023 {
    display: inline;
  }
}

.btn span[data-involver="text-mobile"] {
  display: none;

  @include vp-767 {
    display: inline-block;
  }
}
