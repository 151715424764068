.program-publication .program__list-wrapper {
  padding: 5px;
}

.program-publication .program__icon {
  display: block;
  align-self: flex-end;
}

.program-publication h2 {
  display: none;
}

.program-publication .program__list {
  max-width: unset;
  padding: 0;
}
