.link {
  display: flex;
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  transition: color $trans-default;

  @include no-touch {
    &:hover {
      color: $color-dodger-blue;
    }
  }
}
