.footer {
  padding: 46px 0 50px;

  background-color: $color-matisse;
  color: $color-default-white;

  font-size: 18px;

  @include vp-1023 {
    padding: 40px 0 71px;
  }

  @include vp-767 {
    padding: 41px 0 103px;
  }

  .container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @include vp-1023 {
      flex-direction: column;
      padding: 0 59px;
    }

    @include vp-767 {
      padding: 0 25px 0 30px;
    }
  }

  &__copyright-block {
    display: flex;
    flex-direction: column;
    max-width: 700px;

    @include vp-1023 {
      order: 1;
    }
  }

  &__copyright {
    margin: 0;
    line-height: 32px;
    max-width: 350px;

    &:first-child {
      display: none;

      @include vp-1023 {
        display: block;
      }
    }

    @include vp-1023 {
      max-width: unset;
    }
  }


  &__contacts-block {
    .title--h5 {
      margin: 0;
      margin-bottom: 9px;
      text-transform: uppercase;
      color: inherit;
    }
  }

  &__contacts-list {
    list-style-type: none;
    padding: 0;
    margin: 0;

    @include vp-1023 {
      margin-bottom: 63px;
    }
  }

  &__contacts-item {
    svg {
      width: 21px;
      height: 21px;
      margin-right: 5px;
      margin-top: 2px;
      fill: currentColor;
    }

    @include no-touch {
      &:hover > svg {
        fill: currentColor;
      }
    }

    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
}
