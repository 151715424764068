.main-list {
  margin-bottom: 100px;
  padding: 0;

  @include vp-1023 {
    margin-bottom: 66px;
  }

  @include vp-767 {
    margin-bottom: 61px;
    padding: 0;
  }

  &--column {
    color: $color-default-black;

    ol {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: repeat(3, max-content);
      grid-auto-flow: column;
      max-width: 100%;
      column-gap: 40px;

      @include vp-1023 {
        width: 93%;
        column-gap: 0;
      }

      @include vp-767 {
        grid-auto-flow: row;
        grid-template-columns: 1fr;
        column-gap: 40px;
      }
    }

    .main-list__item {
      width: 455px;
      color: $color-default-black;

      @include vp-1023 {
        width: 100%;
      }
    }
  }

  &__item-title {
    margin-top: 0;
    margin-bottom: 26px;

    @include vp-1023 {
      margin-bottom: 5px;
      font-size: 18px;
      line-height: 25px;
    }
  }

  &__item-text {
    margin: 0;

    @include vp-1023 {
      font-size: 18px;
      line-height: 27px;
    }
  }
}

.main-list__inner {
  padding: 0 30px 0 45px;
  margin: 0 auto;
}

.main-list h2 {
  font-family: "Roboto", "Arial", sans-serif;
  margin: 0 0 42px;

  @include vp-1023 {
    margin: 0 0 29px;
    max-width: 40%;
  }

  @include vp-767 {
    padding-left: 12px;
    margin: 0 0 26px;
    max-width: 90%;
  }
}

.main-list--column h2 {
  margin-top: 0;
  margin-bottom: 80px;
  font-weight: 700;
  font-size: 42px;
  line-height: 49px;
  color: $color-default-black;

  @include vp-1023 {
    margin-bottom: 42px;
    font-size: 24px;
    line-height: 28px;
  }

  @include vp-767 {
    margin-bottom: 21px;
  }
}

.main-list ol {
  color: $color-grey-accordion;
  counter-reset: section;
  list-style-type: none;
  margin: 0;
  padding: 0;

  @include vp-1023 {
    margin: 0 auto;
  }
}

.main-list li {
  position: relative;
  font-family: "Roboto", "Arial", sans-serif;
  font-size: 18px;
  line-height: 28px;
  transition: 0.3s ease;
  margin: 0 0 41px;
  padding: 0 0 0 70px;
  color: $color-grey-accordion;

  @include no-touch {
    &:hover {
      &::before {
        background-color: $color-dodger-blue;
        color: $color-default-white;
      }
    }
  }

  @include vp-1023 {
    font-size: 14px;
    line-height: 22px;
    margin: 0 0 22px;
    padding: 0 0 0 48px;
  }

  @include vp-767 {
    padding: 0 0 0 41px;
  }

  &::before {
    position: absolute;
    top: -13px;
    left: 0;
    counter-increment: section;
    content: counters(section, ".") " ";
    color: $color-dodger-blue;
    font-family: "Arial", sans-serif;
    font-size: 22px;
    line-height: 34px;
    padding: 7px 17px 5px;
    border-radius: 10px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16);
    font-weight: 700;
    margin-right: 10px;
    transition: background-color $trans-default, color $trans-default;

    @include vp-1023 {
      font-size: 16px;
      line-height: 25px;
      padding: 2px 11px 2px;
      top: -5px;
      left: 2px;
      border-radius: 6px;
    }
  }
}

.main-list--main {
  margin-bottom: 107px;

  &.liders-list {
    margin-bottom: 107px;

    @include vp-1023 {
      margin-bottom: 75px;
    }

    @include vp-767 {
      margin-bottom: 53px;
    }
  }

  .title--h3 {
    margin-top: 0;
    margin-left: 230px;
    margin-bottom: 111px;
    font-size: 42px;
    line-height: 49px;

    @include vp-1023 {
      margin-left: 0;
      margin-bottom: 28px;
      font-size: 24px;
      line-height: 28px;
    }

    @include vp-767 {
      font-size: 24px;
      line-height: 28px;
    }
  }

  ol {
    margin: 0 auto;
    max-width: 967px;
  }
}
