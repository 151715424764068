.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;

  display: flex;
  flex-direction: column;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  background-color: rgba($color-default-black, 0.8);
  transform: scale(1.2);
  visibility: hidden;
  opacity: 0;

  transition: opacity $trans-modal,
    transform $trans-modal,
    visibility $trans-modal;

  pointer-events: none;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &__wrapper {
    position: relative;

    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 100%;
    padding: 60px 40px;

    @include vp-767 {
      padding: 60px 16px;
    }
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    cursor: pointer;
  }

  &__content {
    position: relative;
    width: 627px;
    padding: 36px 40px 49px;
    background-color: $color-default-white;
    border-radius: 15px;
    box-shadow: 1px 2px 10px rgba($color-default-black, 0.1);

    @include vp-767 {
      width: 328px;
      padding: 36px 13px 49px;
    }
  }

  &__close-btn {
    position: absolute;
    top: 22px;
    right: 22px;
    z-index: 1;
    width: 40px;
    height: 40px;
    padding: 0;
    background: $color-transparent;
    border: none;
    cursor: pointer;

    @include vp-767 {
      top: 0;
    }
  }

  &--responsive {
    .modal__content {
      width: 100%;
    }
  }

  &--fit-content {
    .modal__content {
      width: auto;

      @include vp-1023 {
        width: 100%;
      }
    }
  }

  &--no-scale {
    transform: none;
  }

  &--preload {
    transition: none;
  }

  &.is-active {
    transform: scale(1);
    visibility: visible;
    opacity: 1;
    pointer-events: auto;
  }

  &__description {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__title {
    margin-top: 0;
    margin-bottom: 12px;
    font-weight: 700;
    font-size: 29px;
    line-height: 34px;
    text-align: center;
  }

  &__text {
    margin-top: 0;
    margin-bottom: 30px;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: $color-modal-text;

    @include vp-767 {
      font-size: 16px;
      line-height: 27px;
      margin-bottom: 23px;
    }
  }
}
