.main-nav {
  @include vp-1023 {
    display: none;
    width: 100%;
    padding: 96px 20px 20px;
    position: absolute;
    top: 0;
    left: 0;
    background-color: $color-matisse;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    margin-left: 60px;
    padding: 0;
    list-style: none;

    @include vp-1023 {
      width: max-content;
      margin: 0 auto;
      padding: 0;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__item {
    margin-right: 31px;

    &:last-of-type {
      margin-right: 0;
    }

    @include vp-1023 {
      margin-right: 0;
      padding-right: 20px;
    }
  }

  &__link {
    font-weight: 300;
    font-size: 18px;
    line-height: 21px;
    color: $color-grey;
    transition: color $trans-default;

    @include no-touch {
      &:hover {
        color: $color-matisse;
      }
    }

    &.is-active {
      font-weight: 700;

      @include no-touch {
        &:hover {
          color: $color-grey;
        }
      }

      @include vp-1023 {
        @include no-touch {
          &:hover {
            color: $color-default-white;
          }
        }
      }
    }

    @include vp-1023 {
      display: block;
      padding: 34.5px 0;
      color: $color-default-white;

      @include no-touch {
        &:hover {
          color: $color-nobel;
        }
      }
    }
  }

  &.is-open {
    height: 100vh;
    display: block;
    transform: translateY(0);
    transition: all $trans-default;
    overflow: scroll;
    z-index: 100;
  }
}

.no-scroll {
  overflow: hidden;
}
