.main-block {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 1052px;
  margin-bottom: 100px;
  padding: 64px 0 47px;

  @include vp-1023 {
    min-height: 676px;
    padding: 216px 0 0;
    margin-bottom: 66px;
  }

  @include vp-767 {
    min-height: 574px;
    margin-bottom: 161px;
  }

  &__quote-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    width: 44%;
    font-style: italic;
    font-weight: 400;
    font-size: 22px;
    line-height: 32px;
    color: $color-default-white;

    @include vp-1023 {
      display: none;
    }
  }

  &__quote {
    margin-top: 0;
    margin-bottom: 18px;
  }

  &__author {
    align-self: flex-end;
    padding-right: 63px;
  }
}

.main-block h1 {
  text-align: center;
  margin: 0 auto 87px;

  @include vp-1023 {
    max-width: 450px;
    margin: 0 auto 32px;
  }

  @include vp-767 {
    margin: 0 auto 33px;
    max-width: 331px;
  }

  @include vp-320-374 {
    font-size: 35px;
  }
}

.main-block .container {
  max-width: 1035px;
  margin-bottom: 0;

  @include vp-1023 {
    min-width: unset;
    margin-bottom: 0;
  }

  @include vp-389 {
    padding: 0 4px;
  }
}

.main-block .container div[data-picture="desktop"] {
  @include vp-1023 {
    display: none;
  }
}

.main-block .container div[data-picture="tablet"] {
  display: none;

  @include vp-768-and-vp-1023 {
    display: block;
  }
}

.main-block .container div[data-picture="mobile"] {
  display: none;

  @include vp-767 {
    display: block;
  }
}

.main-block .picture-wrapper {
  position: absolute;
  top: -118px;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 111%;

  background: $color-background-default;
  background-clip: content-box;

  @include vp-1023 {
    top: 0;
    height: 100%;
  }

  @include vp-767 {
    height: 126%;
    top: -48px;
  }
}

.picture-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.picture-wrapper img[data-img="desktop"] {
  @include vp-1023 {
    display: none;
  }
}

.picture-wrapper img[data-img="tablet"] {
  display: none;

  @include vp-768-and-vp-1023 {
    display: block;
  }
}

.picture-wrapper img[data-img="mobile"] {
  display: none;

  @include vp-767 {
    display: block;
  }
}

.container .main-block__text-block {
  & > .btn--white {
    margin: 0 0 210px;

    &::before {
      content: "";
      display: inline-block;
      background: url("../img/svg/arrow.svg") no-repeat;
      width: 18px;
      height: 32px;
      background-size: 18px 27px;
      margin: 3px 6px 0 0;
      padding: 0;
    }

    @include vp-1023 {
      display: none;
    }
  }

  .btn--blue {
    margin: 0 auto 60px;
  }

  & > p {
    text-align: center;
    margin: 0;
  }
}

.main-block__text-block {
  &--quote {
    h1 {
      margin-bottom: 32px;
    }
  }
}

.main-block__under-text p {
  font-family: "Roboto", "Arial", sans-serif;
  font-size: 18px;
  line-height: 34px;
  font-weight: 300;
  color: $color-default-white;
  margin: 0 0 50px;

  @include vp-767 {
    margin: 0 0 18px;
  }
}

.main-block__under-small-text{
  margin-bottom: 52px;

  @include vp-1023 {
    display: none;
  }
}


.main-block__under-small-text p {
  font-size: 21px;
  color: $color-default-white;
  text-align: center;
  margin: 0;


}


.main-block__under-text span[data-text="undertext-desktop"] {
  @include vp-1023 {
    display: none;
  }
}

.main-block__under-text span[data-text="undertext-tablet"] {
  display: none;

  @include vp-768-and-vp-1023 {
    display: block;
  }
}

.main-block__under-text span[data-text="undertext-mobile"] {
  display: none;

  @include vp-767 {
    display: block;
  }
}
