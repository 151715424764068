.liders-organization__inner h2 {
  font-family: "Roboto", "Arial", sans-serif;
  font-size: 42px;
  line-height: 49px;
  margin: 0 0 103px;
  padding: 0;

  @include vp-1023 {
    font-size: 24px;
    line-height: 28px;
    margin: 0 0 38px;
  }
}

.liders-organization__inner .options__offline-schedule {
  width: 100%;
  margin: 0 0 20px;

  @include vp-767 {
    margin: 0 0 16px;
  }
}

.liders-organization__inner .options__date {
  padding-right: 17px;

  @include vp-767 {
    padding-right: 0;
  }
}

.liders-organization__inner .options__hand-out {
  margin-bottom: 19px;

  &:last-child {
    margin: 0;
  }

  &:first-of-type {
    @include vp-767 {
      margin-bottom: 11px;
    }
  }
}

.liders-organization__inner .options__hand-out-support {
  display: none;

  & + svg {
    display: none;
  }

  @include vp-1023 {
    display: block;
  }
}

.liders-organization__inner .options__hand-out:first-of-type + .options__hand-out {
  display: none;

  @include vp-1023 {
    display: block;
  }
}

.options__additional-options {
  @include vp-767 {
    & h3 {
      padding-left: 14px;
      margin: 0 0 15px;
    }
  }
}

.liders-organization span {
  @include vp-1023 {
    display: none;
  }
}

.liders-organization__inner > p {
  text-align: center;
  margin: 0;
}
