.video-sec {
  margin-bottom: 170px;

  @include vp-1023 {
    margin-bottom: 74px;
  }

  @include vp-767 {
    margin-bottom: 74px;
  }

  &__title {
    display: none;

    @include vp-1023 {
      display: block;
      margin-top: 0;
      margin-bottom: 27px;
      font-weight: 700;
      font-size: 24px;
      line-height: 28px;
    }
  }

  &__wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 339px 20px 20px;
    min-height: 1069px;
    width: 100%;

    @include vp-1023 {
      padding-top: 145px;
      padding-bottom: 69px;
      min-height: 615px;
    }

    @include vp-767 {
      padding-top: 139px;
      max-height: 615px;
    }
  }

  &__video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;

    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__tag {
    margin-top: 0;
    margin-bottom: 16px;
    font-weight: 700;
    font-size: 40px;
    line-height: 52px;
    color: $color-default-white;

    @include vp-1023 {
      font-weight: 400;
      font-size: 18px;
      line-height: 25px;
    }

    @include vp-767 {
      width: 50%;
      text-align: center;
    }
  }

  &__title-sub.title {
    margin-top: 0;
    margin-bottom: 40px;
    width: 80%;
    text-align: center;

    @include vp-1023 {
      width: 65%;
      font-size: 40px;
      line-height: 50px;
      margin-bottom: 33px;
      order: -1;
    }

    @include vp-767 {
      width: 100%;
    }

    span {
      @include vp-1023 {
        display: block;
      }
    }
  }

  &__btn.btn {
    width: 391px;
    max-width: 391px;

    @include vp-1023 {
      margin-bottom: 0;
      margin-top: auto;
      width: 222px;
    }
  }

  &__cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    transition: opacity $trans-default;
    opacity: 1;
    visibility: visible;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &.is-hidden {
      opacity: 0;
      visibility: hidden;
    }
  }
}
