.business-program p {
  font-size: 18px;
  line-height: 27px;
  color: $color-grey-accordion;
  margin: 0;
  padding: 0;

  &:first-of-type {
    margin-bottom: 11px;

    @include vp-1023 {
      margin: 0 0 12px;
    }
  }

  @include vp-1023 {
    font-size: 14px;
    line-height: 21px;
  }
}

.container.business-program__inner {
  padding: 0 47px;

  @include vp-1023 {
    padding: 0 29px;
  }

  @include vp-767 {
    padding: 0 19px;
  }
}

.business-program__inner > p {
  text-align: center;
  margin: 0;
}

.business-program__text-block {
  margin-bottom: 40px;

  @include vp-1023 {
    margin-bottom: 44px;
  }

  @include vp-767 {
    margin-bottom: 37px;
  }
}

.business-program h2 {
  margin: 0 0 36px;

  @include vp-1023 {
    text-indent: 0;
    margin: 0 0 30px;
  }
}

.business-program__title-desk {
  @include vp-1023 {
    display: none;
  }
}

.business-program__title-mob {
  display: none;

  @include vp-1023 {
    display: block;
  }
}

.business-program__btn {
  .business-program__btn-desk {
    @include vp-1023 {
      display: none;
    }
  }

  .business-program__btn-mob {
    display: none;

    @include vp-1023 {
      display: inline;
    }
  }
}

.business-program span {
  @include vp-1023 {
    display: none;
  }
}
