.main-liders {
  min-height: 1050px;
  margin: 0;
  margin-bottom: 100px;

  @include vp-1023 {
    margin-bottom: 66px;
  }

  @include vp-767 {
    margin-bottom: 162px;
  }
}

.container.main-liders__inner {
  @include vp-1024 {
    padding: 0 15px;
  }
}

.main-liders__inner h1 {
  max-width: unset;

  @include vp-767 {
    max-width: 350px;
  }
}

.main-liders__inner .btn--white {
  transform: translateX(30px);
}
