.loyalty-list__inner {
  @include vp-767 {
    margin-bottom: 0;
  }
}

.loyalty-list h2 {
  margin: 0 0 72px;

  @include vp-767 {
    margin: 0 0 30px;
  }
}

.loyalty-list__inner li {
  font-family: "Roboto", "Arial", sans-serif;

  @include vp-1023 {
    font-size: 16px;
    line-height: 27px;
  }
}

.loyalty-list__inner ol {
  @include vp-1023 {
    padding-left: 0;
    margin: 0;
  }
}

.loyalty-list__inner ol > li {
  padding: 0 0 0 76px;
  margin: 0 0 66px;

  &:last-child {
    margin: 0;
  }

  &::before {
    top: -12px;

    @include vp-1023 {
      top: 1px;
      left: 3px;
    }
  }

  @include vp-1023 {
    font-size: 16px;
    padding-left: 44px;
    margin: 0 0 38px;
  }

  @include vp-767 {
    margin: 0 0 21px;
  }
}

.loyalty-list__inner ul {
  padding: 33px 0 0;
}

.loyalty-list__inner ul li {
  list-style: none;
  margin: 0 0 32px;
  padding: 0 0 0 3px;

  &::before {
    display: none;
  }

  @include vp-1023 {
    margin: 0 0 29px;
  }

  @include vp-767 {
    &:last-child {
      margin: 0 0 20px;
    }
  }
}
