.organizational-info__inner h2 {
  margin: 0 0 103px;

  @include vp-1023 {
    max-width: 300px;
    margin: 0 0 38px;
  }
}

.organizational-info span {
  @include vp-1023 {
    display: none;
  }
}

.organizational-info__inner .options__line {
  & h3 {
    margin: 0 0 12px;
  }
}

.organizational-info__inner > p {
  text-align: center;
  margin: 0;
}

.organizational-info__inner div:last-child {
  margin-bottom: 0;
}
