.involver-championship {
  @include vp-1023 {
    display: none;
  }

  & p {
    font-family: "Roboto", "Arial", sans-serif;
    font-size: 18px;
    line-height: 25px;
    color: $color-default-black;
  }

  &__icon-360{
    margin-top: 180px;


    svg{
      margin-left: 60px;
    }
  }
}

.involver-championship__main {
  max-width: 940px;
  margin: 0 auto;

  & > p {
    text-align: center;
    margin: 0;
  }

  & .btn {
    margin-top: 0;
  }
}

.involver-championship__block-1 {
  margin-bottom: 90px;

  &-inner {
    text-align: center;
  }

  & .picture-wrapper {
    width: 911px;
    height: 561px;
    margin-bottom: 40px;
  }

  & p {
    max-width: 802px;
    text-align: left;
    margin: 0 auto;
  }
}

.involver-championship__block-2 {
  margin-bottom: 72px;
  margin-top: 34px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  &-inner {
    display: flex;
    max-width: 883px;
  }

  & .picture-wrapper {
    width: 465px;
    height: 275px;
    flex-shrink: 0;
  }

  & img,
  & source {
    flex-shrink: 0;
  }

  & p {
    order: -1;
    margin-right: 28px;

    margin-top: 21px;
    margin-left: 30px;
  }
}

.involver-championship__block-3 {
  margin-bottom: 90px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;

  &-inner {
    display: flex;
    max-width: 883px;
  }

  & .picture-wrapper {
    width: 465px;
    height: 275px;
    flex-shrink: 0;
    margin-right: 28px;
  }

  & img,
  & source {
    flex-shrink: 0;
  }

  & p {
    margin-top: 22px;
    margin-left: 22px;
    margin-bottom: 0;
  }
}
