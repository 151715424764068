.contacts {
  margin-bottom: 170px;

  @include vp-1023 {
    margin-bottom: 110px;
  }

  @include vp-767 {
    margin-bottom: 74px;
  }

  &__title {
    margin-top: 0;
    margin-bottom: 80px;
    margin-left: 230px;

    @include vp-1023 {
      margin-bottom: 26px;
      margin-left: 0;
    }
  }

  &__block {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    width: 627px;
    padding: 40px;

    background-color: $color-default-white;
    border-radius: 15px;
    box-shadow: 1px 2px 10px rgba($color-default-black, 0.1);

    @include vp-1023 {
      padding: 28px 35px;
      width: 45%;
    }

    @include vp-767 {
      width: 100%;
      padding: 30px 13px;
    }
  }

  &__text-bold {
    margin-top: 0;
    margin-bottom: 12px;
    font-weight: 700;
    font-size: 29px;
    line-height: 34px;

    @include vp-1023 {
      font-size: 24px;
      line-height: 28px;
    }
  }

  &__text {
    margin-top: 0;
    margin-bottom: 30px;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: $color-modal-text;

    @include vp-1023 {
      font-size: 18px;
      line-height: 25px;
      color: $color-default-black;
    }

    @include vp-767 {
      width: 80%;
    }
  }

  .form .custom-textarea {
    @include vp-767 {
      padding-bottom: 51px;
    }
  }

  .form__submit.btn {
    @include vp-767 {
      width: 91%;
      max-width: 100%;
    }
  }
}
