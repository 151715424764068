.all-publications {
  & .program__list-wrapper {
    margin-right: 0;
    margin-left: 0;
  }

  & ul {
    @include vp-1023 {
      padding: 0;
      margin: 0;
    }
  }
}
