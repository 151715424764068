.involver-app {
  @include vp-1023 {
    display: none;
  }

  &__block {
    display: flex;
    max-width: 1000px;
    margin: 0 auto;
  }

  & .picture-wrapper {
    width: 757px;
    height: 566px;
    margin-top: 32px;
    margin-left: 106px;
  }
}

.involver-app__inner {
  overflow: hidden;
}

.involver-app__text-block {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  margin-right: 25px;

  & h2 {
    font-family: "Roboto", "Arial", sans-serif;
    font-size: 92px;
    line-height: 108px;
    color: $color-default-black;
    margin-top: 220px;
    margin-bottom: 0;
  }

  & p {
    font-family: "Roboto", "Arial", sans-serif;
    font-size: 18px;
    line-height: 30px;
    color: $color-default-black;
  }
}
