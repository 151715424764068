.custom-input,
.custom-textarea {
  position: relative;

  display: flex;
  width: auto;
  padding: 20px 0;

  label {
    display: flex;
    align-items: center;
    width: 100%;

    input,
    textarea {
      flex-grow: 1;
      min-height: 40px;
      margin-left: 30px;
      padding: 10px;

      border: 2px solid transparent;
      border-radius: 5px;
      outline: none;

      transition: border $trans-default;

      &:hover {
        border: 2px solid rgba($color-default-black, 0.5);
        background-color: $color-light-grey;
      }

      &:focus {
        border: 2px solid rgba($color-default-black, 0.5);
        background-color: $color-light-grey;
      }
    }
  }

  &__error {
    position: absolute;
    right: 0;
    bottom: 0;

    color: $color-torch-red;

    opacity: 0;

    transition: opacity $trans-default;
  }

  &.is-invalid {
    input {
      border: 2px solid $color-torch-red;

      &:focus,
      &:hover {
        border: 2px solid rgba($color-torch-red, 0.5);
      }
    }

    .custom-input__error {
      opacity: 1;
    }
  }
}

.custom-input.hidden-input {
  padding: 0 !important;
}
