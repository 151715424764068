.schedule {
  box-shadow: 0 2px 5px 0 $color-accordion-hover;
  border-radius: 10px;
  min-width: 317px;
  text-align: right;
  padding: 20px 7px 20px;

  @include vp-1023 {
    min-width: 166px;
    margin: 0;
    padding: 20px 13px 20px;
  }

  @include vp-767 {
    padding: 20px 9px 20px 13px;
  }
}
