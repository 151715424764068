.menu-program {

  &__container {
    display: flex;
    flex-wrap: wrap;
    position: relative;


  }

  &__text-block {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 0 256px;
    margin-top: 40px;

    z-index: 1000;

    @include vp-1023 {
      display: none;
    }
  }

  &__title {
    font-size: 60px;
    line-height: 130.19%;
    margin: 0;
    text-align: center;
    margin-top: 40px;
    pointer-events: none;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }


  &__text-block>.btn--white::before {
    content: "";
    display: inline-block;
    background: url(../img/svg/arrow.svg) no-repeat;
    width: 18px;
    height: 32px;
    background-size: 18px 27px;
    margin: 3px 6px 0 0;
    padding: 0;
  }

  &__blocks {
    display: flex;
    width: 100%;

    @include vp-1023 {
      margin-top: 0;
    }


    @include vp-767 {
      flex-wrap: wrap;
    }

  }

  &__item {
    overflow: hidden;
    display: flex;
    align-items: center;
    width: 50%;
    position: relative;
    box-sizing: border-box;
    flex-direction: column;
    min-height: 1047px;
    cursor: pointer;

    @include vp-1023 {
      min-height: 675px;
    }

    @include vp-767 {
      width: 100%;
      justify-content: flex-start;


      align-items: flex-start;
      min-height: 330px;
      width: 100%;
    }
  }

  &__item-text {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 278px 6px 58% 16px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;

    @include vp-1023 {

      padding: 190px 3px 58% 3px;
    }

    @include vp-767 {
      padding: 32px 18px 14px;
      align-items: flex-start;
      justify-content: space-between;
    }
  }


  &__item:nth-child(1) {

    @include vp-767 {
      order: 2
    }

  }

  &__item:nth-child(2) {
    @include vp-767 {
      order: 1
    }
  }



  &__item:hover>&__item-title {
    color: $color-hover-menu;
  }


  &__item:nth-child(1) {

    @include vp-767 {
      order: 2
    }

  }

  &__item:nth-child(2) {
    @include vp-767 {
      order: 1
    }
  }



  &__item:hover>&__item-title {
    color: $color-hover-menu;
  }

  &__item:hover .overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #000;
    opacity: 0.3;
    z-index: 10;
    transition: opacity 0 opacity 0.3 3s ease;

    @include no-touch {
      &:hover {
        &::after {
          opacity: 0;
        }
      }
    }

  }

  &__item-text:hover>p>.btn--blue {
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    background-color: transparent;
    color: $color-dodger-blue;
  }


  &__item-text>p {
    margin-bottom: 0px;

    @include vp-767 {
      display: none;
    }
  }


  &__item-text>a {
    display: none;
    font-size: 16px;
    line-height: 167.7%;

    text-decoration-line: underline;
    color: $color-default-white;

    @include vp-767 {
      display: block;
      // position: absolute;
      // bottom: 0;
      // margin-bottom: 14px;
    }

  }

  &__item-title {
    text-align: center;
    font-size: 80px;
    line-height: 130.19%;
    margin: 0;
    margin-bottom: 50px;
    z-index: 150;
    word-wrap: break-word;


    @include vp-1023 {
      font-size: 40px;
      line-height: 1.2;
      margin-bottom: 40px;
    }

    @include vp-767 {
      text-align: left;
      margin-bottom: 0px;

    }
  }

  &__img {
    overflow: hidden;
    z-index: -1;
    width: 100%;
    height: 100%;
    background: #c4c4c4;
    background-clip: content-box;


    @include vp-1023 {
      top: 0px;
    }

    >img {

      max-width: 100%;
      height: auto;
      max-height: 100%
    }

    >.picture-wrapper {
      height: 100%;
    }
  }



  &__img .picture-wrapper[data-picture="desktop"] {
    @include vp-1023 {
      display: none;
    }
  }

  &__img .picture-wrapper[data-picture="tablet"] {
    display: none;

    @include vp-768-and-vp-1023 {
      display: block;
    }
  }

  &__img .picture-wrapper[data-picture="mobile"] {
    display: none;

    @include vp-767 {
      display: block;
    }
  }

}
