.program {
  margin: 0 auto 170px;

  @include vp-1023 {
    max-width: auto;
    margin-bottom: 84px;
    padding-bottom: 4px;
  }

  &__title {
    margin-top: 0;
    margin-left: 230px;
    margin-bottom: 80px;

    @include vp-1023 {
      margin-left: 0;
      margin-bottom: 26px;
      max-width: 20%;
    }

    @include vp-767 {
      max-width: 80%;
    }
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 35px;
    row-gap: 35px;
    margin: 0 auto;
    max-width: 1082px;
    list-style: none;
    padding-left: 45px;
    padding-right: 45px;

    @include vp-1023 {
      grid-template-rows: 155px 155px;
      grid-template-rows: 1fr 1fr;
      grid-auto-flow: column;
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
      row-gap: 20px;
      column-gap: 28px;
      padding-left: 0;
      padding-right: 0;
      width: 100%;
    }
  }

  &__item {
    border-radius: 15px;
    box-shadow: 1px 2px 10px rgba($color-default-black, 0.1);
    min-height: 155px;

    @include vp-767 {
      min-width: 250px;
    }

    /*@include vp-1023 {
      width: 251px;
    }*/
  }

  &__link {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 24px 15px;
    width: 100%;
    height: 100%;
    background-color: $color-default-white;
    border-radius: 15px;
    transition: background-color $trans-default;

    @include vp-1023 {
      padding: 18px 16px 18px 22px;
    }

    @include no-touch {
      &:hover {
        background-color: $color-matisse;

        .program__title-sub,
        .program__text {
          color: $color-default-white;
        }
      }
    }
  }

  &__title-sub {
    margin-top: auto;
    margin-bottom: 6px;
    font-weight: 700;
    font-size: 22px;
    line-height: 30px;
    transition: color $trans-default;

    @include vp-1023 {
      display: flex;
      flex-direction: column;
      margin-bottom: 0;
      margin-top: auto;
    }
  }

  &__text {
    margin: 0;
    width: 95%;
    font-size: 18px;
    line-height: 25px;
    color: $color-slategray;
    transition: color $trans-default;

    @include vp-1023 {
      display: none;
    }
  }

  &__icon {
    display: none;

    @include vp-1023 {
      display: block;
      align-self: flex-end;
    }
  }

  &__list-wrapper {
    padding-bottom: 10px;

    @include vp-1023 {
      margin-left: 20px;
      margin-right: 20px;
    }
  }
}
