.header {
  border-color: $color-default-white;
  background-color: $color-default-white;
  border-bottom: 1px solid $color-gray-stone;

  &__wrap {
    padding-top: 6px;
    padding-bottom: 6px;
    width: 100%;

    .container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right: 75px;
      padding-left: 75px;

      @include vp-1023 {
        padding-right: 20px;
        padding-left: 20px;
        padding-top: 14px;
        padding-bottom: 6px;
      }
    }

    &--blue {
      color: $color-default-white;
      background-color: $color-matisse;

      @include vp-1023 {
        display: none;
      }
    }
  }

  &__text {
    margin: 0;
    line-height: 131%;
    font-style: italic;
  }

  &__links-list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 0;
    margin-bottom: 0;
    margin-right: 13px;
    padding-left: 0;
    list-style: none;
  }

  &__links-item {
    margin-right: 25px;
    font-size: 16px;
    line-height: 19px;
    font-weight: 300;

    &:last-child {
      margin-right: 0;
    }
  }

  &__logo {
    display: block;
    opacity: 1;
    transition: opacity $trans-default;

    @include no-touch {
      &:hover {
        opacity: 0.5;
      }
    }

    &--main {
      @include no-touch {
        &:hover {
          opacity: 1;
        }
      }
    }

    svg {
      width: 158px;
      height: 71px;

      @include vp-1023 {

        width: 105px;
        height: 47px;
      }
    }
  }

  &__toggles {
    display: none;
    background-color: inherit;
    border: none;

    @include vp-1023 {
      display: block;
      position: absolute;
      top: 27px;
      right: 12px;
      z-index: 999;
    }
  }

  &__toggle {
    width: 100%;
    height: 100%;
    cursor: pointer;
    color: $color-white-smoke;
    transition: color $trans-default;

    @include no-touch {
      &:hover {
        color: $color-silver;
      }
    }

    &--open {
      svg {
        width: 35px;
        height: 30px;
      }
    }

    &--close {
      transition: opacity $trans-default;
      opacity: 1;

      @include no-touch {
        &:hover {
          opacity: 0.5;
        }
      }

      svg {
        width: 30px;
        height: 30px;
      }
    }
  }

  .is-hidden {
    display: none;
  }
}
