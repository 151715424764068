.container.loyalty-program__inner {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 0 45px;

  & .list-icon svg {
    fill: transparent;
    width: 109px;
    height: 109px;
  }

  @include vp-1023 {
    padding: 0 20px;
  }

  @include vp-767 {
    padding: 0 20px;
  }
}

.loyalty-program__inner h2 {
  max-width: 400px;
  margin: 0 0 76px;
  padding: 0;

  @include vp-1023 {
    margin: 0 0 44px;
  }

  @include vp-767 {
    margin: 0 0 71px;
  }
}

.loyalty-program__advantages {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 0 auto;
  padding: 0;

  @include vp-1023 {
    justify-content: space-between;
  }
}

.loyalty-program__advantages-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 222px;
  margin: 0;
  padding: 0;

  @include vp-767 {
    margin-bottom: 50px;
  }
}

.loyalty-program__advantages-item p {
  font-family: "Roboto", "Arial", sans-serif;
  color: $color-grey-accordion;
  font-size: 18px;
  line-height: 32px;
  margin: 0;
  padding: 0;

  @include vp-1023 {
    font-size: 16px;
    line-height: 27px;
  }
}

.loyalty-program__advantages-item svg {
  fill: transparent;
  margin-bottom: 30px;

  @include vp-767 {
    margin-bottom: 31px;
  }
}

.loyalty-program__advantages-item[data-advantage="plus"] {
  min-width: 43%;
  flex: 1;

  @include vp-800 {
    min-width: 39%;
  }

  @include vp-767 {
    min-width: 100%;
  }
}

.loyalty-program__advantages-item[data-advantage="plus"] p {
  max-width: 150px;

  @include vp-1023 {
    max-width: 220px;
  }
}

.loyalty-program__advantages-item[data-advantage="two-squares"] {
  min-width: 50%;
  flex: 1;
  margin-bottom: 83px;

  @include vp-800 {
    min-width: 56%;
    margin-bottom: 59px;
  }

  @include vp-767 {
    min-width: 100%;
  }
}

.loyalty-program__advantages-item[data-advantage="two-squares"] p {
  max-width: 400px;

  @include vp-1023 {
    max-width: 280px;
  }
}

.loyalty-program__advantages-item[data-advantage="aim"] {
  min-width: 14%;
  flex: 1;
  padding-left: 10px;

  @include vp-800 {
    min-width: 38%;
  }

  @include vp-767 {
    min-width: 100%;
    padding: 0;
    margin-bottom: 66px;
  }
}

.loyalty-program__advantages-item[data-advantage="aim"] p {
  @include vp-767 {
    max-width: 200px;
  }
}

.loyalty-program__advantages-item[data-advantage="sun"] {
  min-width: 65%;
  flex: 1;

  @include vp-800 {
    min-width: 56%;
  }

  @include vp-767 {
    min-width: 100%;
  }
}

.loyalty-program__advantages-item[data-advantage="sun"] p {
  max-width: 300px;

  @include vp-1023 {
    max-width: 270px;
    margin-bottom: 60px;
  }

  @include vp-767 {
    margin-bottom: 0;
  }
}

.loyalty-program__advantages-item[data-advantage="round"] {
  min-width: 16%;
  flex: 1;
  transform: translate(4px, -6px);

  @include vp-1023 {
    transform: unset;
  }

  @include vp-800 {
    min-width: 100%;
    transform: unset;
  }

  @include vp-767 {
    min-width: 100%;
  }
}

.loyalty-program__advantages-item[data-advantage="round"] p {
  @include vp-800 {
    max-width: 200px;
  }
}
