// Vendor
// ---------------------------------
@import "vendor/normalize";
// Swiper 7.4.1
@import "vendor/swiper";

// Global
// ---------------------------------
@import "variables";
@import "mixins";
@import "functions";
@import "global/fonts";
@import "global/reboot";
@import "global/utils";
@import "global/container";
// Blocks
// ---------------------------------
@import "blocks/header";
@import "blocks/main-nav";
@import "blocks/footer";
@import "blocks/modal";
@import "blocks/btn";
@import "blocks/custom-input";
@import "blocks/custom-toggle";
@import "blocks/custom-select";
@import "blocks/sessions.scss";
@import "blocks/main-block.scss";
@import "blocks/main-list.scss";
@import "blocks/accordion.scss";
@import "blocks/data-accordion.scss";
@import "blocks/business-program.scss";
@import "blocks/program-users.scss";
@import "blocks/organizational-info.scss";
@import "blocks/options.scss";
@import "blocks/title.scss";
@import "blocks/main-video.scss";
@import "blocks/content-sec";
@import "blocks/list-icon";
@import "blocks/list-rect";
@import "blocks/main-loyalty.scss";
@import "blocks/program-description.scss";
@import "blocks/loyalty-program.scss";
@import "blocks/content.scss";
@import "blocks/loyalty-list.scss";
@import "blocks/main-liders.scss";
@import "blocks/liders-list.scss";
@import "blocks/liders-advantages.scss";
@import "blocks/liders-content.scss";
@import "blocks/liders-organization.scss";
@import "blocks/top-requests.scss";
@import "blocks/up-btn.scss";
@import "blocks/program";
@import "blocks/video-sec";
@import "blocks/reviews";
@import "blocks/partners";
@import "blocks/form";
@import "blocks/gallery";
@import "blocks/contacts";
@import "blocks/schedule.scss";
@import "blocks/link.scss";

@import "blocks/list-card";
@import "blocks/team-member-page";
@import "blocks/team-member-form";
@import "blocks/team";

@import "blocks/plug.scss";
@import "blocks/main-publications.scss";
@import "blocks/book.scss";
@import "blocks/all-publications.scss";
@import "blocks/main-involver.scss";
@import "blocks/qr-involver.scss";
@import "blocks/involver-app.scss";
@import "blocks/involver-championship.scss";
@import "blocks/program-publication.scss";

@import "blocks/menu-program.scss";
@import "blocks/btn-wrapper.scss";

@import "blocks/main-consultations.scss";
@import "blocks/consultations-container.scss";
// ---------------------------------
// ❗❗❗ запрещено использовать изображения в css, касается как jpg \ png, так и svg

// ❗ обязательно используйте @media для отключения ховеров на тач устройствах
// но не используем для текстовых полей ( input, textarea )
/* @media (hover: hover), screen and (min-width: 0\0) {
  &:hover,
  &:focus {
    color: $color-default-white;
  }
} */

// адаптив пишем внутри каждого класса (смотрим container.scss)

// для фикса проблем с vh на iOS в сборке подключен скрипт
// используя vh на проекте задавайте их также как в примере в utils.scss

// для любых transition обязательно указывайте transition-property
// transition: $trans-default ❌ ---> transition: color $trans-default ✅
