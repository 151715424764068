.list-rect {
  margin: 0;
  padding-left: 0;
  list-style: none;
  font-weight: 700;
  color: $color-dark-gray;

  &__item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 14px 143px 14px 21px;
    min-height: 63px;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    background: $color-default-white;
    border-radius: 7px;
    box-shadow: 1px 3px 10px rgba($color-default-black, 0.06);

    @include vp-1023 {
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      justify-content: center;
      align-items: center;
      padding: 14px 66px 13px 18px;
      min-height: 51px;
      text-align: center;

      & span {
        font-style: 16px;
        text-align: left;
      }
    }

    @include vp-767 {
      padding: 15px 64px 15px 11px;
    }

    &:not(:last-child) {
      margin-bottom: 19px;
    }
  }

  &__item-desk {
    @include vp-1023 {
      display: none;
    }
  }

  &__item-mob {
    display: none;

    @include vp-1023 {
      display: inline;
    }
  }
}
