.list-icon {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 26%;
  row-gap: 120px;
  margin: 0;
  padding-left: 0;
  list-style: none;

  @include vp-1023 {
    column-gap: 62px;
  }

  @include vp-767 {
    grid-template-columns: 1fr;
    column-gap: 0;
    row-gap: 90px;
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 134px;
    margin-bottom: 18px;

    @include vp-1023 {
      margin-bottom: 30px;
    }

    @include vp-767 {
      min-height: max-content;
    }
  }

  &__text {
    margin: 0;
    text-align: center;
  }
}
