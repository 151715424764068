.list-card{
  margin: 0 68px;
  display: grid;
  justify-content: center;

  grid-template-columns: repeat(3, 205px);
  column-gap: 23px;
  row-gap: 15px;
  padding-left: 0;
  list-style: none;

  @include vp-767{
    grid-template-columns: repeat(2, minmax(100px, 160px));
    row-gap: 45px;
    margin: 0;
  }


  &__item:last-child{
    grid-column: 2 span;

    @include vp-767{
      grid-column: 1 span;
    }

  }

  &__link{

    @include vp-767{

      height: 180px;

    }
  }


  &__img-container{

      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 230px;

      cursor: pointer;

      margin-bottom: 18px;
      overflow: hidden;
      padding: 0;

      background-color: $color-default-white;
      box-shadow: 1px 2px 10px $color-shadow;
      border-radius: 15px;
      border: 1px solid $color-default-white;

      @include no-touch {
        &:hover,
        &:focus {
          border: 1px solid $color-silver-tree;
        }
      }

      @include vp-1023 {
        margin-bottom: 30px;
      }

      @include vp-767 {
        min-height: 180px;
      }

      .picture-wrapper{
        height: 100%;
      }
  }

  &__text {
    margin: 0;
    text-align: center;
    line-height: 1.4;
  }
}
