.team{
  margin-top: 55px;
  margin-bottom: 100px;

  @include vp-1023{
    margin-bottom: 66px;
    margin-top: 80px;
  }

  @include vp-767{
    margin-top: 0px;
  }

  &__main-block{
    display: none;

    @include vp-767{
      display: block;
    }
  }


  &__button-show{

    display: flex;
    margin: 0 auto;
    margin-top: 0;

    @include vp-767{
      display: none;
    }
  }


  &__cards-list{

    margin-bottom: 0px;


    @include vp-1023{
      margin-bottom: 0px;

    }

    &--show{
      display: block;
    }

    &--hidden{
      display: none;
    }


    .title{

      @include vp-767{
        display: none;
      }

    }
  }



}
