.liders-list {
  padding: 0;
}

.liders-list__inner h2 {
  margin: 0 0 46px;

  @include vp-767 {
    margin: 0 0 27px;
  }
}

.liders-list__inner ol {
  max-width: 840px;

  @include vp-1023 {
    margin: 0;
  }

  @include vp-767 {
    padding-left: 0;
  }
}

.liders-list__inner ol > li {
  margin: 0 0 44px;

  &::before {
    top: -8px;
    left: -2px;

    @include vp-767 {
      top: -3px;
      left: 5px;
      width: 30px;
      height: 28px;
    }
  }

  @include vp-1023 {
    margin: 0 0 28px;
  }

  @include vp-767 {
    padding: 0 0 0 49px;
    line-height: 26px;
    margin: 0 0 18px;
  }
}
