.options {
  margin: 0 0 0 auto;
  color: $color-default-black;

  .content-sec__title + & {
    margin-top: 103px;

    @include vp-1023 {
      margin-top: 0;
    }
  }

  &__title {
    margin-top: 0;
    margin-bottom: 16px;
    font-weight: 700;
    font-size: 22px;
    line-height: 26px;
    color: $color-default-black;

    @include vp-1023 {
      margin-bottom: 13px;
      font-size: 18px;
      line-height: 21px;
    }
  }

  &__rect {
    box-shadow: 0 2px 5px 0 $color-accordion-hover;
    border-radius: 10px;
    min-width: 317px;
    text-align: right;
    padding: 7px 7px 27px;
    margin-right: 10px;

    &:not(:last-child) {
      margin-right: 13px;
    }

    @include vp-1023 {
      min-width: 166px;
      padding: 7px 13px 27px;
    }
  }

  &__text {
    margin-top: 0;
    margin-bottom: 51px;
    font-size: 22px;
    line-height: 35px;
    color: $color-default-gray;

    @include vp-1023 {
      margin-bottom: 30px;
      font-size: 16px;
      line-height: 27px;
    }
  }

  &__btn {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    width: 391px;
    line-height: 21px;

    @include vp-1023 {
      width: 222px;
    }
  }

  &__btn-desk {
    @include vp-1023 {
      display: none;
    }
  }

  &__btn-mob {
    display: none;

    @include vp-1023 {
      display: inline;
    }
  }

  .options__date-text {
    @include vp-1023 {
      margin-right: 22px;
    }
  }

  &__col {
    @include vp-767 {
      width: 100%;
    }
  }

  & > p {
    font-size: 22px;
    line-height: 35px;
    color: $color-default-gray;
    margin: 0;

    @include vp-1023 {
      font-size: 16px;
      line-height: 27px;
    }
  }
}

.options svg {
  fill: transparent;
  justify-self: flex-end;
  flex-shrink: 0;
}

.options__line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 21px;
  column-gap: 30px;

  @include vp-1023 {
    margin: 0 auto 14px auto;
  }

  @include vp-374 {
    flex-wrap: wrap;
  }

  @include vp-400 {
    column-gap: 20px;
  }
}

.options__line h3 {
  text-indent: 8px;
  margin: 0 0 17px;
  padding: 0;

  @include vp-1023 {
    margin: 0 0 4px;
  }

  @include vp-767 {
    text-indent: 5px;
  }
}

.options__offline {
  display: flex;
  flex-direction: column;
  width: 50%;

  @include vp-374 {
    width: 100%;
    margin-bottom: 25px;
  }
}

.options__offline-schedule svg {
  width: 18px;
  height: 18px;
}

.options__online {
  display: flex;
  flex-direction: column;
  width: 50%;

  @include vp-374 {
    width: 100%;
  }
}

.options__date {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  padding: 0 0 0 19px;

  @include vp-1023 {
    padding: 0;
  }
}

.options__date p {
  font-family: "Roboto", "Arial", sans-serif;
  font-size: 18px;
  line-height: 21px;
  margin: 0;
  color: $color-default-black;

  @include vp-1023 {
    margin-right: 11px;
    font-size: 16px;
    line-height: 19px;
    text-align: left;
  }

  @include vp-767 {
    margin-top: -7px;
  }
}

.options__date svg {
  position: relative;
  top: -2px;
  left: -7px;
  width: 34px;
  height: 34px;
}

.options__date span {
  @include vp-1023 {
    display: block;
  }
}

.options__people-quantity {
  position: relative;
  display: flex;
  justify-content: space-between;
  box-shadow: 0 2px 5px 0 $color-accordion-hover;
  border-radius: 10px;
  margin: 0 0 21px;
  padding: 15px 17px 15px 25px;

  @include vp-1023 {
    padding: 15px 17px 15px 13px;
  }

  @include vp-409 {
    padding: 20px 17px 20px 13px;
  }

  & span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    opacity: 0;
    text-shadow: 0 0 0 $color-default-black;
    transition: opacity $trans-default;
  }

  & svg {
    cursor: pointer;
  }

  @include vp-1023 {
    margin: 0 0 23px;
  }
}

.options__people-quantity.options__appear-text {
  & p {
    text-shadow: 0 0 8px $color-default-black;
    color: transparent;
    transition: color $trans-default text-shadow $trans-default;
  }

  & span {
    opacity: 1;
    text-shadow: 0 0 0 $color-default-black;
    transition: opacity $trans-default;
  }
}

.options__people-quantity p {
  font-family: "Roboto", "Arial", sans-serif;
  font-size: 18px;
  line-height: 21px;
  color: $color-default-black;
  text-shadow: 0 0 0 transparent;
  transition: text-shadow $trans-default;
  margin: 0;
  padding: 0;

  @include vp-1023 {
    font-size: 16px;
    line-height: 19px;
    padding: 0;
  }
}

.options__hand-out {
  position: relative;
  display: flex;
  align-items: center;
  box-shadow: 0 2px 5px 0 $color-accordion-hover;
  border-radius: 10px;
  min-height: 90px;
  margin-bottom: 22px;
  padding: 18px 35px 16px 89px;

  &:last-of-type {
    @include vp-1023 {
      display: none;
    }
  }

  @include vp-1023 {
    min-height: unset;
    text-align: center;
    justify-content: center;
    padding: 16px 10px;
    margin-bottom: 11px;
  }
}

.options__hand-out p {
  font-family: "Roboto", "Arial", sans-serif;
  font-size: 18px;
  line-height: 28px;
  margin: 0;
  padding: 0;

  @include vp-1023 {
    font-size: 16px;
    line-height: 21px;
    color: $color-dark-gray;
  }
}

.options__hand-out svg {
  position: absolute;
  top: 30px;
  left: 35px;

  @include vp-1023 {
    display: none;
  }
}

.options__hand-out span {
  @include vp-1023 {
    display: none;
  }
}

.options__hand-out-support span {
  display: block;
}

.options__additional-options > h3 {
  display: none;

  @include vp-1023 {
    display: block;
    margin: 0 0 17px;
  }
}
