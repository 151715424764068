.content p {
  text-align: center;
  margin: 0;
}

.content h2 {
  margin: 0 0 63px;

  @include vp-1023 {
    margin: 0 0 40px;
  }

  @include vp-767 {
    margin: 0 0 24px;
  }
}
