.btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  line-height: 21px;
  color: $color-dodger-blue;
  background-color: $color-default-white;
  border: 1px solid $color-dodger-blue;
  opacity: 1;
  border-radius: 6px;
  cursor: pointer;
  transition: opacity $trans-default;
  box-shadow: 0 4px 15px 0 $color-button-shadow;
  user-select: none;
  margin: 0 auto;
  padding: 11px 63px;

  &:disabled, &--disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  @include vp-1023 {
    font-size: 16px;
    line-height: 19px;
    padding: 15px 27px;
  }

  @include no-touch {
    &:hover,
    &:focus {
      background-color: $color-dodger-blue;
      color: $color-default-white;
    }
  }

  &--white {
    color: $color-nobel;

    @include no-touch {
      &:focus,
      &:hover {
        opacity: 0.8;
        background-color: $color-default-white;
        color: $color-nobel;
        transition: background-color $trans-default, color $trans-default, opacity $trans-default;
      }
    }
  }

  &--white-btn-size {
    font-size: 22px;
    font-weight: 600;
    line-height: 26px;
    border-radius: 10px;
    border: 0;
    padding: 6px 11px;
    margin: 3px 8px 0 4px;
  }

  &--blue {
    background-color: $color-dodger-blue;
    color: $color-default-white;
    transition: background-color $trans-default, border-color $trans-default;

    @include no-touch {
      &:hover {
        background-color: $color-endeavour;
        border-color: $color-endeavour;
        opacity: 1;
      }
    }
  }
}
