.main-block.main-publications {
  @include vp-1023 {
    margin-bottom: 0;
  }

  @include vp-767 {
    margin-bottom: 26px;

    & h1 {
      margin: 0 auto 18px;
    }
  }

  & img,
  & source {
    @include vp-1023 {
      height: 125%;
    }

    @include vp-767 {
      height: 215%;
    }

    @include vp-417 {
      height: 100%;
    }
  }
}

.main-block.main-publications .picture-wrapper {
  background: $color-publication-background;
  @include vp-1024 {
    top: -124px;
  }

  @include vp-1023 {
    overflow: hidden;
  }

  @include vp-890-and-vp-1023 {
    height: 120%;
  }

  @include vp-767 {
    top: 0;
    height: 97%;
  }
}
