.main-block.plug {
  padding: 317px 0 47px;
  margin: 0;

  @include vp-1023 {
    padding: 174px 0 47px;
  }

  @include vp-767 {
    padding: 174px 0 0;
  }
}

.plug h1 {
  margin-bottom: 178px;
  padding: 0 45px;

  @include vp-1023 {
    line-height: 75px;
    margin-bottom: 73px;
    padding: 0;
  }

  @include vp-767 {
    line-height: 55px;
    margin-bottom: 167px;
  }
}

.plug__inner .picture-wrapper {
  top: 0;
  height: 100%;

  @include vp-1023 {
    top: -14px;
    height: 102%;
  }

  @include vp-767 {
    top: 0;
    height: 104%;
  }
}

.plug__inner.main-block__inner {
  max-width: unset;
  padding: 0;
}

.plug .btn--blue {
  background-color: $color-plug-button;
  border: 0;
  font-size: 80px;
  line-height: 104px;
  border-radius: 0;
  box-shadow: 8px 10px 0 $color-shadow-plug-button;
  padding: 11px 30px;

  @include vp-1023 {
    font-size: 30px;
    line-height: 39px;
    padding: 22px 30px;
    box-shadow: 4px 7px 0 #acc6de;
  }
}

.plug__inner .main-block__text-block {
  & p {
    text-align: left;
  }
}
