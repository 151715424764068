.accordion {
  & & {
    width: 90%;
    margin: 0 auto;
  }

  &__element:last-child {
    margin-bottom: 0;
  }

  &__element {
    padding: 0;
    margin-bottom: 19px;

    &:last-of-type {
      margin-bottom: 0;
    }

    @include vp-1023 {
      margin-bottom: 20px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    @include vp-767 {
      margin-bottom: 10px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    & svg {
      position: absolute;
      right: 19px;
      top: 50%;
      transform: translateY(-50%);
      width: 21px;
      height: 27px;
      transition: 0.3s ease;
      z-index: 1;

      @include vp-1023 {
        width: 15px;
        height: 25px;
        right: 19px;
      }

      @include vp-767 {
        right: 22px;
      }
    }
  }

  &__element.is-active {
    border-radius: 7px;
    box-shadow: 0 2px 5px 0 $color-accordion-shadow;
  }

  &__element.is-active > &__button-wrapper {
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    box-shadow: unset;

    & button {
      border-top-left-radius: 7px;
      border-top-right-radius: 7px;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      border-bottom: 1px solid $color-light-gray;
      box-shadow: unset;
    }
  }

  &__element.is-active .accordion__content {
    box-shadow: unset;
    border-bottom-right-radius: 7px;
    border-bottom-left-radius: 7px;
  }

  &__button {
    width: 100%;
    padding: 10px;
    text-transform: uppercase;
    cursor: pointer;
  }

  &__wrapper {
    padding: 13px 5px 23px 25px;

    @include vp-767 {
      padding: 13px 5px 23px 0;
    }
  }
}

.accordion__element.accordion__element--rotate-arrow svg {
  transform: translateY(-50%) rotate(-90deg);
}

.accordion__element.accordion__element--change-shadow .accordion__button-wrapper {
  box-shadow: unset;
}

.accordion__button-wrapper {
  position: relative;
}

.accordion .accordion__button span {
  display: none;

  @include vp-1023 {
    display: block;
    font-size: 16px;
    line-height: 19px;
  }
}

.accordion__element button {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  line-height: 26px;
  font-weight: 700;
  min-height: 53px;
  min-width: 100%;
  color: $color-dark-gray;
  border: 0;
  border-radius: 7px;
  box-shadow: 0 2px 5px 0 $color-accordion-shadow;
  text-transform: none;
  transition: 0.3s ease;
  padding: 14px 66px 14px 21px;
  margin: 0;

  &:last-child {
    margin: 0;
  }

  &:focus,
  &:hover {
    background-color: $color-default-white;
    color: $color-dark-gray;
    opacity: 1;
    box-shadow: 0 2px 15px 0 $color-accordion-hover;
    transition: 0.3s ease;
  }

  @include vp-1023 {
    font-size: 0;
    line-height: 19px;
    justify-content: flex-start;
    text-indent: 0;
    min-height: 51px;
    padding: 14px 66px 13px 18px;
    text-align: left;
  }

  @include vp-767 {
    padding: 15px 64px 15px 11px;
  }

  @include vp-389 {
    padding: 16px 64px 15px 11px;
  }
}

.accordion__wrapper ul {
  font-size: 22px;
  line-height: 37px;
  color: $color-grey-accordion;
  max-width: 541px;
  margin: 0 auto;
  list-style-position: inside;

  @include vp-1023 {
    font-size: 16px;
    line-height: 26px;
  }
}

.accordion__wrapper li {
  position: relative;
  margin-bottom: 18px;

  &:last-of-type {
    margin: 0;
  }

  @include vp-767 {
    margin-bottom: 5px;
  }

  li {
    margin-bottom: 5px;
  }


}

