.team-member-pages{
  margin-bottom: 0;

  @include vp-1023{
    margin-bottom: 0;
  }
}

.team-member-page{

  display: none;

  margin-top: 130px;
  @include vp-1023{
    margin-top: 146px;
  }

  @include vp-767{
    margin-top: 42px;
  }


  .team  .team-member-page__title{
    width: 100%;
  }

  &__title--tablet{
    display: none;

    @include vp-1023{
      display: block;
    }
  }

  &__title--desktop{

    @include vp-1023{
      display: none;
    }
  }

  &__title{
    grid-row: 1 / span 1;
    grid-column: 2 / span 2;

    @include vp-1023{
      grid-column: 1 / span 2;
      grid-row: 1 / span 1;
    }

    @include vp-767{
      grid-row: 1 / span 1;
      grid-column: 1 / span 1;
    }

  }

  &__container{
    display: grid;
    column-gap: 52px;

    @include vp-767{
      justify-items: inherit;
    }
  }

  &__img-container{

    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: flex-start;


    @include vp-1023{
      flex-wrap: nowrap;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
    }

    @include vp-767{
      flex-wrap: wrap;
      flex-direction: column;
      align-items: center;
      margin-bottom: 0px;
    }
  }

  &__img{
    border: 1px solid $color-silver-tree;
    border-radius: 7px;
    overflow: hidden;

    margin-bottom: 30px;
    order: 1;

    width: 345px;
    @include vp-1023{
     order: 2;

     margin-bottom: 0;

    }

    @include vp-767{
      order: 1;
    }

    @media (max-width: 390px) {
      width: 100%;
    }


  }

  &__form{
    order: 2;

    grid-row: 2 / span 2;
    grid-column: 1 / span 1;


    @include vp-1023{
      grid-row: 2 / span 1;
      grid-column: 1 / span 1;
    }

    @include vp-1023{
      order: 1;
     }

    @include vp-767{
      margin: 0 auto;

      grid-row: 5 / span 1;
      grid-column: 1 / span 1;
    }
  }



  &__text{
    grid-row: 1 / span 1;
    grid-column: 2 / span 2;

    @include vp-1023{

      grid-row: 3/span 1;

      grid-column: 1/span 1;
    }



  }

  &__about{

    margin-top: 34px;
    grid-row: 1 / span 4;
    grid-column: 2;


    @include vp-1023{
      margin-top: 68px;
      grid-row: 4 /span 1;
      grid-column: 1 / span 3;
    }

    order: 2;

    @include vp-767{
      margin-bottom: 100px;

      @include vp-767{

        margin-top: 31px;

        grid-row: 4 / span 1;
        grid-column: 1 / span 1;
      }
    }

    p{
      margin: 0;
    }

    p:not(:last-child){
      margin-bottom: 40px;
    }

    b{
      color: $color-default-black
    }

  }

  &__button-connect{
    margin: 0 auto;
    display: none;

    grid-area: button;

    order: 2;

    @include vp-767{
      display: block;

      grid-row: 3 / span 1;
      grid-column: 1 / span 1;
    }
  }


  &--show{
    display: block;
  }

  &--hidden{

    display: none;
  }
}
