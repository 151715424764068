.main-loyalty {
  min-height: 1000px;
  margin-bottom: 150px;

  @include vp-1023 {
    min-height: 578px;
    margin-bottom: 116px;
  }

  @include vp-767 {
    margin-bottom: 168px;
  }
}

.container.main-loyalty__inner {
  @include vp-767 {
    padding: 0 10px;
  }
}

.main-loyalty__inner .picture-wrapper {
  height: 100%;

  @include vp-1023 {
    top: -52px;
  }
}

.main-loyalty img {
  height: 117%;

  @include vp-1023 {
    top: -52px;
    height: 126%;
  }

  @include vp-767 {
    height: 100%;
  }
}
