.content-sec {
  margin-bottom: 100px;
  font-size: 18px;
  line-height: 32px;
  color: $color-grey-accordion;

  @include vp-1023 {
    margin-bottom: 66px;

    font-size: 16px;
    line-height: 27px;
  }

  @include vp-767 {
    margin-bottom: 61px;
  }

  & .container > p {
    margin: 0 0 50px;
  }

  & .options__people-quantity {
    margin-bottom: 50px;
  }

  & .program-format svg {
    display: none;

    @include vp-767 {
      display: none;
    }
  }

  & .topics svg {
    fill: transparent;
    justify-self: flex-end;
    flex-shrink: 0;
  }

  & .topics__options .options__hand-out:last-of-type {
    display: flex;
  }

  &__text {
    margin: 0;
    text-align: left;

    &:not(:last-child) {
      margin-bottom: 29px;
    }
  }

  &__title,
  .title {
    margin-top: 0;

    @include vp-1023 {
      margin-bottom: 25px;
      width: 50%;
    }

    @include vp-767 {
      width: 70%;
    }
  }

  &__text-block {
    &:not(:last-child) {
      margin-bottom: 78px;

      @include vp-1023 {
        margin-bottom: 29px;
      }
    }
  }

  &__list-block {
    list-style-type: none;

    & li:not(:last-child){
      margin-bottom: 15px;
    }

    & a {
      color: $color-dodger-blue;
      text-decoration-line: underline;
      cursor: pointer;
      transition: color $trans-default;
    }


    & a:hover {
        @include no-touch {
        color: $color-matisse;
      }

    }
  }

  &__thema-block {
    padding: 32px 40px;
    box-sizing: border-box;
    background-color: $color-default-white;
    box-shadow: 1px 2px 10px $color-shadow;
    border-radius: 16px;

    @include vp-1023 {
      padding: 30px 80px;
    }

    @include vp-767 {
      padding: 30px 43px 24px;
    }
  }

  &__thema-text {
    margin: 0;
    color: $color-grey-accordion;
    font-weight: 700;
    font-size: 26px;
    line-height: 36px;

    @include vp-1023 {
      font-size: 18px;
      line-height: 26px;
      text-align: center;
    }
  }

  .title--h2 {
    margin-bottom: 25px;
  }

  .title--h3 {
    margin-bottom: 50px;

    @include vp-1023 {
      margin-bottom: 25px;
    }
  }

  .title--h4 {
    margin-bottom: 15px;

    @include vp-1023 {
      margin-bottom: 10px;
    }
  }

  .btn--blue {
    margin-top: 63px;

    @include vp-1023 {
      margin-bottom: 30px;
    }
  }

  &--small-title-mob {
    .content-sec__title,
    .title {
      @include vp-1023 {
        max-width: 230px;
      }
    }
  }

  & .list-icon svg {
    width: 109px;
    height: 109px;
  }
}
