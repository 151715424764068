.qr-involver {
  @include vp-1023 {
    display: none;
  }
}

.qr-involver .picture-wrapper {
  width: 711px;
  margin: 0 auto;
}
