.form {
  margin: 0 auto;

  @include vp-1023 {
    width: 100%;
  }

  .custom-input {
    padding-top: 0;
    padding-bottom: 20px;

    @include vp-1023 {
      padding-bottom: 13px;
    }

    @include vp-767 {
      padding-bottom: 12px;
    }
  }

  .custom-textarea {
    padding-top: 0;
    padding-bottom: 33px;

    @include vp-1023 {
      padding-bottom: 49px;
    }

    @include vp-767 {
      padding-bottom: 63px;
    }
  }

  .custom-input label input,
  .custom-textarea label textarea {

    width: inherit;
    margin-left: 0;
    padding: 14px 17px;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    border-radius: 10px;
    background-color: $color-light-grey;

    &::placeholder {
      color: $color-dark-gray;
    }

    @include vp-767 {
      padding: 14px 10px;
    }
  }

  .custom-textarea label textarea {
    min-height: 56px;
  }


  &__submit.btn {
    display: flex;
    width: inherit;
    margin: 0 auto;

    @include vp-767 {
      min-width: 131px;
      padding: 11px;
    }
  }

  &__submit-desk.btn {
    @include vp-767 {
      display: none;
    }
  }

  &__submit-mob.btn {

    display: none;

    @include vp-767 {
      display: block;
    }
  }

  input:-webkit-autofill {
    box-shadow: none;
  }

  .custom-input__error {
    @include vp-1023 {
      bottom: -5px;
    }

    @include vp-767 {
      bottom: -4px;
      font-size: 14px;
      line-height: 18px;
    }
  }
}

.custom-input.hidden-input {
  padding: 0;
  margin: 0;
}
