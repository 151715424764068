.container {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  padding: 0 45px;

  @include vp-1023 {
    padding: 0 20px;
  }

  &--inner {
    max-width: 888px;
  }

  &--limit-width {
    max-width: 1200px;
  }
}
