.main-block.book {
  @include vp-767 {
    padding: 170px 0 0;
    margin: 0;
  }

  @include vp-417 {
    padding: 20px 0 0;
  }

  & img,
  & source {
    @include vp-767 {
      border-radius: 10px;
    }
  }

  & .picture-wrapper {
    // height: 196px;
    @include vp-767 {
      width: calc(100% - 40px);
      transform: translateX(20px);
      height: 350px;
      top: 0;
    }

    @include vp-417 {
      height: 192px;
    }
  }

  & h1 {
    @include vp-767 {
      margin-bottom: 80px;
    }

    @include vp-417 {
      margin-bottom: 30px;
    }
  }
}

.container.main-block__inner.book__inner {
  @include vp-417 {
    padding: 0 20px;
  }

  h1, p{
    text-align: left;
  }

  h1 {
    margin: 0 0 32px;
  }
}


.book__inner > .main-block__text-block{
  max-width: 600px;

  @include vp-1023{
    max-width: 550px;
  }
}

.main-block__text-block span[data-text="book-desktop"] {
  @include vp-767 {
    display: none;
  }
}

.main-block__text-block span[data-text="book-mobile"] {
  display: none;

  @include vp-767 {
    display: block;
    color: $color-slategray;
  }
}
