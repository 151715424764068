.sessions {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 0 0 83px;
  padding: 66px 0 47px;

  @media (max-width: $vp-1023) {
    padding: 216px 0 0;
    margin-bottom: 100px;
  }
}

.sessions h1 {
  text-align: center;
  max-width: 900px;
  margin: 0 auto 87px;
  padding: 0 0 0 30px;

  @media (max-width: $vp-1023) {
    max-width: 500px;
    margin: 0 auto 30px;
    padding: 0 0 0 11px;
  }
}

.sessions img {
  position: absolute;
  width: 100%;
  height: 111%;
  object-fit: cover;
  top: 0;
  left: 0;
  z-index: -1;

  @media (max-width: $vp-1023) {
    top: 0;
    height: 116%;
  }

  @media (max-width: $vp-767) {
    height: 126%;
    top: -47px;
  }
}

.sessions img[data-img="desktop"] {
  @media (max-width: $vp-767) {
    display: none;
  }
}

.sessions img[data-img="mobile"] {
  display: none;

  @media (max-width: $vp-767) {
    display: block;
  }
}

.sessions__inner {
  min-width: 940px;
  margin-bottom: 221px;

  @media (max-width: $vp-1023) {
    min-width: unset;
    margin-bottom: 111px;
  }
}

.sessions__offers-btn {
  margin: 0;
  padding: 0;

  @media (max-width: $vp-1023) {
    display: none;
  }
}


.sessions__offers-btn a {
  font-family: "Arial", sans-serif;
  font-size: 22px;
  line-height: 26px;
  background-color: $color-default-white;
  border-radius: 10px;
  color: $color-nobel;
  margin: 0 0 210px;
  padding: 9px 18px 9px 18px;

  &::before {
    content: "";
    display: inline-block;
    background: url("../img/svg/arrow.svg");
    width: 14px;
    height: 23px;
    background-size: 14px 23px;
    margin: 0 8px 0 0;
    padding: 0;
  }
}

.sessions__get-program-btn {
  text-align: center;
  margin: 0;
  padding: 0;
}

.sessions__get-program-btn a {
  font-family: "Arial", sans-serif;
  font-size: 18px;
  line-height: 21px;
  background-color: $color-dodger-blue;
  color: $color-default-white;
  border-radius: 6px;
  margin: 0;
  padding: 12px 65px;

  @media (max-width: $vp-1023) {
    font-size: 16px;
    line-height: 19px;
    padding: 16px 29px;
  }
}

.sessions__up-btn {
  text-align: right;
  width: 100%;
  margin: 0;
  padding: 0 55px 0 0;

  @media (max-width: $vp-1023) {
    padding: 0 20px 0 0;
  }
}

.sessions__up-btn a {
  position: relative;
  width: 61px;
  height: 61px;
  border-radius: 50%;
  background-color: $color-dodger-blue;
  margin: 0;
  padding: 0;

  &::before {
    content: "";
    position: absolute;
    top: 22%;
    left: 26%;
    background: url("../img/svg/arrow-up.svg") no-repeat;
    width: 30px;
    height: 35px;
  }
}
