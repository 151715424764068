.container.consultations-container {
  & h2 {
    font-size: 30px;
    line-height: 35px;
    padding-left: 14px;

    @include vp-1023 {
      padding-left: 33px;
    }

    @include vp-767 {
      width: 100%;
      padding-left: 20px;
    }
  }

  & .options {
    padding-left: 114px;

    @include vp-1023 {
      font-size: 18px;
      line-height: 32px;
      padding-left: 52px;
    }

    @include vp-767 {
      padding-left: 5px;
      padding: 0 23px 0 15px;
      margin-bottom: 18px;
    }
  }

      & .options .title--h3 {
      font-size: 22px;
      line-height: 26px;
      margin-bottom: 23px;
      padding-left: 10px;
      max-width: 659px;

      @include vp-1023 {
        margin-bottom: 26px;
      }

      @include vp-767 {
        font-size: 18px;
        line-height: 26px;
        width: 100%;
        margin-bottom: 7px;
        padding: 0;
      }
    }

    & .options__offline-schedule {
      display: none;
      padding: 32px 9px 26px 13px;

      @include vp-767 {
        display: none;
        max-width: 661px;
        padding: 33px 9px 26px 16px;
      }
    }

    & .options__people-quantity {
      max-width: 658px;
      margin-bottom: 41px;
      padding: 12px 17px 12px 15px;

      &:first-of-type {
        display: block;
      }

      @include vp-1023 {
        padding: 7px 17px 5px 15px;
      }

      @include vp-767 {
        padding: 7px 17px 5px 27px;
      }
    }

    & .options__people-quantity span {
      min-width: 500px;

      @media (max-width: 450px) {
        min-width: 300px;
      }
    }

    & .options__people-quantity > p {
      @include vp-1023 {
        font-size: 18px;
        line-height: 32px;
      }

      @include vp-767 {
        font-size: 16px;
        line-height: 19px;
      }
    }

    & .topics__options {
      max-width: 659px;
      margin-bottom: 50px;

      @include vp-767 {
        margin-bottom: 37px;
      }
    }

    & .btn {
      padding: 11px 87px;
      margin: 0;

      @include vp-1023 {
        font-size: 18px;
        line-height: 21px;
      }

      @include vp-767 {
        font-size: 16px;
        padding: 14px 15px;
      }
    }

    & .options__hand-out {
      margin-bottom: 22px;

      &:last-child {
        margin-bottom: 0;
      }

      @include vp-1023 {
        display: block;
        text-align: left;
        padding: 22px 10px 31px 90px;
        margin-bottom: 31px;

        &:first-child {
          padding: 16px 10px;
          padding-left: 90px;
          margin-bottom: 31px;

          @include vp-767 {
            padding: 14px 10px 14px 16px;
            margin-bottom: 16px;
          }
        }

        &:last-child {
          display: block;
        }
      }

      @include vp-767 {
        padding: 20px 10px 20px 20px;
        margin-bottom: 13px;
      }
    }

    & .options__hand-out > p {
      color: $color-default-black;
      font-size: 18px;
      line-height: 28px;

      @include vp-767 {
        color: $color-dark-gray;
        text-align: center;
      }
    }

    & .options__hand-out svg {
      display: block;

      @include vp-1023 {
        top: 19px;
      }

      @include vp-767 {
        display: none;
      }
    }

    & .options__hand-out:first-child svg {
      display: block;

      @include vp-1023 {
        top: 26px;
      }

      @include vp-767 {
        display: none;
      }
    }

    & > p {
      padding-left: 27px;
      max-width: 830px;
      margin: 0 0 100px;

      @include vp-1023 {
        font-size: 18px;
        line-height: 32px;
        max-width: 730px;
        padding-left: 92px;
        margin: 0 0 83px;
      }

      @include vp-767 {
        line-height: 34px;
        padding-left: 19px;
      }
    }
}
