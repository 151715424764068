.program-users {
  margin-bottom: 100px;

  @include vp-1023 {
    margin-top: 66px;
  }

  @include vp-767 {
    margin-top: 61px;
  }

  & p {
    text-align: center;
    margin: 0;
  }

  &__btn-desk {
    @include vp-1023 {
      display: none;
    }
  }

  &__btn-mob {
    display: none;

    @include vp-1023 {
      display: inline;
    }
  }

  & .list-icon__icon svg {
    width: 122px;
    height: 122px;
  }
}
