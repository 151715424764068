.main-consultations {
  padding: 104px 0 47px;
  margin-bottom: 76px;

  & .picture-wrapper {
      @include vp-1023 {
        height: 98%;
      }
  }

  @include vp-1023 {
    padding: 250px 0 0;
    margin-bottom: 27px;
  }

  @include vp-767 {
    padding: 257px 0 0;
  }

  & h1 {
    @include vp-1023 {
      font-size: 40px;
      line-height: 46px;
    }

    @include vp-767 {
      line-height: 49px;
    }
  }

  & .btn.btn--white {
    transform: translate(8px,-71px);
  }

  & + .content-sec {
    margin-bottom: 141px;

    @include vp-767 {
      margin-bottom: 165px;
    }

    & .container {
      padding: 0;
    }
  }
}
