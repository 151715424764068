.program-description {
  margin-bottom: 100px;

  @include vp-1023 {
    margin-bottom: 66px;
  }

  @include vp-767 {
    margin-bottom: 61px;
  }
}

.program-description__inner {
  @include vp-1023 {
    padding: 0 20px;
  }
}

.program-description__inner p {
  font-family: "Roboto", "Arial", sans-serif;
  font-size: 18px;
  line-height: 32px;
  color: $color-grey-accordion;
  margin: 0 0 30px;
  padding: 0;

  &:last-child {
    margin: 0;
  }

  @include vp-1023 {
    font-size: 16px;
    line-height: 27px;
  }
}

.program-description__main-text {
  margin-bottom: 31px;
}

.program-description__additional-text {
  @include vp-1023 {
    display: none;
  }
}
