.liders-content {
  margin: 0 0 166px;
  margin-bottom: 100px;

  @include vp-1023 {
    margin-bottom: 66px;
  }

  @include vp-767 {
    margin-bottom: 61px;
  }
}

.liders-content__inner {
  margin: 0 auto;
}

.liders-content__inner h2 {
  margin: 0 0 61px;

  @include vp-1023 {
    margin-bottom: 34px;
  }
}

.liders-content__inner .accordion {
  max-width: unset;

  @include vp-767 {
    margin: 0 auto 36px;
  }
}

.liders-content__inner .accordion__element {
  margin: 0 0 20px;

  @include vp-767 {
    margin-bottom: 4px;
  }
}

.liders-content__inner button {
  align-items: center;
  padding: 14px 69px 14px 21px;
}

.liders-content__inner > p {
  text-align: center;
  margin: 0;
}
